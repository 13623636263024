

body {
  height: 92vh !important;
}
.App {
  text-align: center;
}
#root {
  height: inherit !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.themeTxtColor {
  color: #142454;
  font-weight: 700;
}
label {
  color: #142454;
  font-weight: 700;
}
.pageBody {
  margin-top: 4.8%;
  position: fixed;
  /* top: 10%; */
  margin-left: 16%;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-radius: 25px 0 0 0;
  max-height: 100vh;
  min-height: 80vh;
  overflow: auto;
  scroll-behavior: smooth;
  width: -webkit-fill-available;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: -moz-available;
}

.btn-primary:hover {
  color: #142454 !important;
  background-color: #ffa400 !important;
  border-color: #ffa400 !important;
}
.btn-primary {
  color: #142454 !important;
  background-color: #fbb434 !important;
  border-color: #fbb434 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 720px) {
  .pageBody {
    top: 0% !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn:focus {
  box-shadow: none !important;
}

.hide-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.rsetpasswod p{
  font-size: 16px;
}

.addpassrd {
  position: relative;
  margin-bottom: 10px;
}
.input-paswodicon {
  position: absolute;
  top: 5px;
  right: 18px;
  z-index: 999;
}

.passwordreue p{
  color: #142454;
  font-size: 16px;
  font-weight: 700;
}
.passwordreue li {
  color: #142454;
  font-size: 17px;
  font-weight: 600;
  list-style: disc;
}

.passwordreue {
  padding-top: 20px;
}


.extend-rental {
  padding-left: 0px;
}

.extend-rental li {
  list-style: disc;
  font-size: 14px;
  font-weight: 500;
  color: #393939;
}