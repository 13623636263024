#header {
  width: 220px;
}
#header .pro-sidebar {
  height: 100vh;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  position: fixed;
  top: 0;
  width: 15%;
  min-width: 15%;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: white;
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: rgba(20, 36, 84, 0.1);
  border-radius: 15px;
}
#header .pro-sidebar-inner .pro-menu-item:not(.rentalsMenu):hover,
.menuRentals:hover {
  background: rgba(20, 36, 84, 0.1);
  border-radius: 15px;
}
#header .logo {
  padding: 20px;
}
.logotext {
  justify-content: center;
  display: flex;
}
.staticHeader {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.pro-sidebar .pro-menu a,
.pro-sidebar .pro-menu a:hover {
  text-decoration: none;
  color: black;
}

.userImage {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.user-details {
  text-decoration: none;
  color: inherit;
}
.user-details:hover {
  text-decoration: none;
  color: inherit;
}
.loginPageBody {
  display: flex;
  justify-content: center;
  height: 95vh;
}
.footerRow {
  height: 10px;
}
.userImage img {
  width: 37px;
  height: 37px;
  border-radius: 10px;
}
.notificationIco {
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  align-items: center;
  justify-content: center;
  display: flex;
}
/* width */
::-webkit-scrollbar {
  width: 0.1px;
}
.pageBody,
body {
  overflow-y: unset;
  scrollbar-color: white white;
  scrollbar-width: thin !important;
  scrollbar-arrow-color: white;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.1px grey;
  border-radius: 0.1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fcfcfc;
}

.userProfileGreet {
  font-weight: 700;
  font-size: 12px;
}

.payBillTotalFont {
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
}

.cl14245 {
  color: #142454;
}
.userName {
  font-size: 16px;
  font-weight: 500;
  color: #142454;
}
.topHeader {
  font-size: 26px;
  color: #142454;
  font-weight: 700;
  padding-left: 10px;
  padding-top: 10px;
}
.pro-item-content .dashboardMargin {
  margin-right: 7px;
  /* padding-right: 53px !;  */
}

.pro-item-content .rentalMargin {
  margin-right: 11px;
  margin-left: 4px;
  color: #142454;
}

.pro-item-content .invoiceMargin {
  margin-right: 10px;
  margin-left: 3px;
}

.pro-item-content .usagelMargin {
  margin-right: 9px;
}

.pro-item-content .orderlMargin {
  margin-right: 11px;
}

.pro-item-content .myaccMargin {
  margin-right: 7px;
  margin-left: 2px;
}

.pro-item-content .logoutMargin {
  margin-left: 2px;
  margin-right: 11px;
}

/* .dashboardMargin {
  padding-right: 50px;
} */

.pro-item-content {
  font-size: 16px;
  font-weight: 600;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 5px 8px 20px;
}
.mobileIcoLogo {
  display: none;
}
.pro-sidebar-content {
  max-height: 100%;
  overflow: auto;
}
@media only screen and (max-width: 992px) {

  .filterContainer{
height: unset !important;

  }
  }
@media only screen and (max-width: 720px) {


  html {
    overflow: hidden;
  }
  .topHeader {
    display: none;
  }
  .HamburgerMenu {
    width: 35px;
    height: 2px;
    background-color: black;
    margin: 6px 0;
  }
  #header .pro-sidebar {
    width: 0%;
    min-width: 0%;
  }
  .pageBody {
    margin-top: 15.5%;
    margin-left: 0%;
  }
  .notificationIco {
    height: 43px;
  }
  .notificationIco img {
    width: 45%;
  }
  .mobileIcoLogo {
    display: block;
  }
  .HamMenuContainer {
    padding: 14px 0 6px 14px;
  }
  .HambergerClose {
    position: absolute;
    top: 0;
    right: 11px;
    font-size: 38px;
    color: black;
    width: 40px;
  }
}

.rentalIcon {
  margin-left: 7px;
}
.pro-sidebar .pro-menu a:before {
  content: unset !important;
}

.pro-sidebar .pro-menu {
  padding-top: 0px;
}

@media only screen and (max-width: 370px) {
  .pageBody {
    margin-top: 16.5%;
  }
}
