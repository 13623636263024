.pageMargin {
  padding: 16px;
  padding-top: 0;
  scroll-behavior: smooth;
  margin-bottom: 5%;
}
.pageMarginR {
  padding: 20px;
  margin-top: -20px;
  scroll-behavior: smooth;
  margin-bottom: 5%;
}
.RcardHeader,
.RentalConrainer2 {
  margin-bottom: 8px;
}

.RcardHeaderNumber {
  padding: 0;
  font-weight: 700;
  font-size: 25px;
  margin-top: -6px;
  margin-left: -25px;
}

.RdataContainer,
.RdataContainer3 {
  margin-bottom: 15px;
}

.sms,
.RdataContainer1 {
  background-color: #f6f8f9;
}
.RcardHeader {
  font-size: 20px;
  font-weight: 700;
}

.RdataContainer1 {
  border-radius: 25px;
  padding: 24px;
  padding-bottom: 52px;
}

.RdataContainer2 {
  background-color: #fdf7f3;
  border-radius: 25px;
  padding: 24px;
}

.RdataContainer3 {
  background-color: #fdf7f3;
  border-radius: 25px;
  padding: 19px;
}

.sms,
.data,
.vnum {
  border-radius: 25px;
}

.sms {
  padding: 20px;
}

.data {
  background-color: #f6f7f5;
  padding: 20px;
  padding-bottom: 50px;
}
.vnum {
  background-color: #f6f3f3;
  padding: 20px;
  padding-bottom: 56px;
  height: 172px;
}

.min {
  text-align: right;
  font-weight: 18px;
}

.dd {
  outline: initial;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
}

.date {
  text-align: right;
}

/* .dropdown-btn{
    margin-left: -400px;
}

.datetime{
    margin-right: -50px;
}

.cardHeader{
    margin: auto;
} */

.lm {
  background-color: #e2f1fa;
  border-radius: 20px;
  margin-bottom: 17px;
  margin-right: 0;
}

.RSimgBox {
  background: white;
  border-radius: 15px;
  padding: 10px;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RimgBox {
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-left: 7px;
}
/* .gCard {
  border-radius: 25px;
  background-color: #f4f6f7;
  min-height: 245px;
} */

.gCardR {
  border-radius: 25px;
  background-color: #f4f6f7;
  min-height: auto;
}

.gCardR .btn.btn-primary {
  background-color: #1d4d5a !important;
  border-color: #1d4d5a !important;
  color: white !important;
}

.gContainerR {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  height: 140px;
}
.SmallFont2 {
  color: #142454;
  font-weight: bold;
  font-size: 20px;
  /* clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap; */
}

.RdataContainer1 .SmallFont1,
.sms .SmallFont1 {
  color: #4b758c;
  font-weight: bold;
  font-size: 18px;
}

.RdataContainer2 .SmallFont1 {
  color: #d95b08;
  font-weight: bold;
  font-size: 18px;
}

.data .SmallFont1 {
  color: #003f3a;
  font-weight: bold;
  font-size: 18px;
}

.vnum .SmallFont1 {
  color: #4c1018;
  font-weight: bold;
  font-size: 18px;
}
.LargeStyle1 .LargeFont1,
.LargeStyle2 .LargeFont1,
.LargeStyle3 .LargeFont1,
.LargeStyle4 .LargeFont1,
.LargeStyle5 .LargeFont1,
.LargeStyle6 .LargeFont1,
.LargeStyle7 .LargeFont1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.LargeStyle1 .LargeFont2,
.LargeStyle6 .LargeFont2 {
  color: #d95b08;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeStyle1 .LargeFont3,
.LargeStyle3 .LargeFont3,
.LargeStyle6 .LargeFont3 {
  color: #d95b08 !important;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
}

.RdataContainer4,
.RdataContainer5 {
  background-color: #f3f4f6;
  padding: 19px !important;
}

.RdataContainer6 {
  background-color: #fdf7f3;
  padding: 19px !important;
}

.RdataContainer7 {
  background-color: #fff5f5;
}

.RdataContainer3 .progress,
.LargeStyle3 .progress,
.RdataContainer6 .progress {
  background-color: #f9e7db;
}

.RdataContainer3 .pb,
.LargeStyle3 .pb,
.RdataContainer6 .pb {
  background-color: #e9a172;
  border-radius: 25px;
}

.LargeStyle2 .LargeFont2,
.LargeStyle3 .LargeFont2,
.LargeStyle4 .LargeFont2 {
  color: #4b5a41;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeStyle2 .progress,
.LargeStyle4 .progress {
  background-color: #e2e5e4;
}

.LargeStyle2 .pb,
.LargeStyle4 .pb {
  background-color: #979f93;
  border-radius: 25px;
}

.LargeStyle5 .LargeFont2,
.LargeStyle7 .LargeFont2 {
  color: #fb3434;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeStyle5 .LargeFont3,
.LargeStyle7 .LargeFont3 {
  color: #fb3434;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}

.LargeStyle5 .progress,
.LargeStyle7 .progress {
  color: #ffe2e2;
}

.LargeStyle5 .pb,
.LargeStyle7 .pb {
  background-color: #f78b8b;
  border-radius: 25px;
}

.LargeFont1 {
  text-align: left;
}
.LargeFont2 {
  text-align: right;
}

.spaceBtw {
  align-content: space-between;
}

.progress {
  height: 12px;
}
.setImagepadding {
  padding-right: 0px;
  padding-left: 3px;
}

.alignitemcenter {
  align-items: center;
}

.update {
  color: #4c1018 !important;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0%;
}

.update:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dropdown.dd .dropdown-toggle::after {
  margin-left: 4.255em;
}

.RentalGeneralInfoCard .gContainer {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  height: 174px;
}

.setheight {
  height: 172px;
}

.paddingTopDashboard {
  padding-top: 3rem;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 360px) {
  .pageMargin {
    margin-bottom: 130px !important;
  }
}
@media only screen and (max-width: 720px) {
  .setheight {
    height: 100%;
  }

  .gBody {
    font-size: 14px;
  }
  .paddingTopDashboard {
    padding-top: 0px;
  }
  .pageMargin {
    margin-bottom: 75px;
  }
  .pageMarginR {
    margin-bottom: 75px;
  }
  .RcardHeaderNumber {
    padding: 0;
    font-weight: 700;
    font-size: 21px;
    margin-top: -1px;
    margin-left: -35px;
    white-space: nowrap;
  }
  .mtopM {
    margin-top: 1rem !important;
  }
  .vnum {
    padding-bottom: 15px;
  }
  .heightpadding {
    padding-top: 18px;
  }
  .datetime {
    padding-top: 10px;
    padding-bottom: 8px;
  }

  .LargeFont2 {
    text-align: left;
    padding-top: 15px;
    padding-left: 6px;
  }

  .LargeFont3 {
    padding-top: 10px;
  }

  .progress {
    margin-top: 17px;
    padding-left: 0px;
    margin-left: 7px;
  }

  /* .RcardHeader {
    margin-top: 25px;
  } */

  .datetimeM {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-left: 6px;
  }
  .padd .modalClass {
    padding: 0px;
  }

  .CCpopup,
  .ExtendRentalpopup,
  .DataUsagepopup {
    padding: 0px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.activeStatus {
  background-color: #f6f7f5;
  border-radius: 25px;
}

.ActiveBox {
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  margin-left: 10px;
}
.Rstatus {
  margin-left: 51px;
  margin-top: 22px;
}

.UdataContainer1 {
  background-color: #f6f7f5;
}
.LargeUsageStyle1 .LargeFont1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.LargeUsageStyle1 .LargeFont2 {
  color: #4b5a41;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeUsageStyle1 .LargeFont3 {
  color: #4b5a41;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}

.LargeUsageStyle1 .progress {
  background-color: #e5e7e3;
}

.LargeUsageStyle1 .pb {
  background-color: #98a092;
  border-radius: 25px;
}

.UdataContainer2 {
  background-color: #f6f8f9;
}
.LargeUsageStyle2 .LargeFont1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.LargeUsageStyle2 .LargeFont2 {
  color: #4b758c;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeUsageStyle2 .LargeFont3 {
  color: #4b758c;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}

.LargeUsageStyle2 .progress {
  background-color: #e5ebee;
}

.LargeUsageStyle2 .pb {
  background-color: #98b0bd;
  border-radius: 25px;
}

.UdataContainer3 {
  background-color: #f6f4f5;
}
.LargeUsageStyle3 .LargeFont1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.LargeUsageStyle3 .LargeFont2 {
  color: #4a1a3b;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeUsageStyle3 .LargeFont3 {
  color: #4a1a3b;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}

.LargeUsageStyle3 .progress {
  background-color: #e6cecd;
}

.LargeUsageStyle3 .pb {
  background-color: #977c8f;
  border-radius: 25px;
}

.UdataContainer4 {
  background-color: #fdf7f3;
}

.LargeUsageStyle4 .LargeFont1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.LargeUsageStyle4 .LargeFont2 {
  color: #d95b08;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.LargeUsageStyle4 .LargeFont3 {
  color: #d95b08;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}

.LargeUsageStyle4 .progress {
  background-color: #f9e7bd;
}

.LargeUsageStyle4 .pb {
  background-color: #e9a172;
  border-radius: 25px;
}

.UsagepaddingL {
  padding-left: 25px;
}

.UsagepaddingR {
  padding-right: 35px;
}

.LargeUsageStyle1 .useageBox,
.LargeUsageStyle2 .useageBox,
.LargeUsageStyle3 .useageBox,
.LargeUsageStyle4 .useageBox {
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
}

.timeStyle,
.dateStyle {
  float: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
.datetime {
  margin-right: 30px;
}

.Unlimitedfair {
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px;
  color: #000000;
}

.boxCurrentBilling {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 7px;
  background: white;
}

.TextHeader {
  font-weight: 700;
  font-size: 20px;
}

/* .RmarginTop{
  margin-top: -50px;
} */

@media only screen and (max-width: 576px) {
  .RcardHeader{
font-size: 14px;
  
}

.RcardHeaderNumber {
 
  font-size: 14px;
 

}
}