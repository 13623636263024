.authCard {
  background: rgba(20, 36, 84, 0.04) !important;
  border-radius: 25px !important;
}
/* .row {
  height: inherit;
} */
.loaderCustom {
  position: absolute !important;
  margin-left: 10px;
}
.cardHeader {
  font-size: 20px;
  font-weight: 700;
  padding-left: 22px;
}
.btn-secondary {
  color: #fff;
  /* background-color: #4C1018;
    border-color: #4C1018; */
  border-radius: 10px;
}
.statusPaid .btn-secondary {
  color: #fff;
  background-color: #4b5a41;
  border-radius: 10px;
}
.authFooter {
  /* position: absolute; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  margin-left: 15px;
}
.loginReg {
  text-decoration: underline;
}
.form-control,
.btn-primary {
  border-radius: 10px !important;
}
.btn-primary {
  font-weight: 700 !important;
}
.inheritColor {
  color: inherit;
}
.form-group label {
  margin-bottom: 4px;
}
.errorText {
  color: red;
}
.inputIcon {
  float: right;
  margin-right: 6px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.mobileRentalContainer {
  display: none;
}

@media only screen and (max-width: 720px) {
  .mobileRentalContainer {
    display: block;
  }
  .dektopRentalContainer {
    display: none;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}