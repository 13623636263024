.ExtendRentalCardSize .modal-dialog .modal-content {
  width: 442px;
  background: #ffffff;
  border-radius: 25px;
}

.ExtendRentalCardSize .modal-dialog {
  width: 442px;
}
.addDataPopup .modal-dialog {
  height: 90vh;
  display: flex;
  align-items: center;
}
.ERfont1 {
  font-weight: 700;
  font-size: 28px;
  color: #142454;
  text-align: center;
  margin-left: 37px;
}

.ERfont2 {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.ERfont3 {
  font-weight: 700;
  font-size: 16px;
  color: #142454;
}

.ERfont4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #142454;
  text-align: center;
}

.ERConBtn {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 39px;
  background: #fbb434;
  border-radius: 10px;
  border-style: none;
  font-weight: 700;
  font-size: 16px;
  color: #142454;
}

.VRContainer {
  background: #fdf7f3;
  border-radius: 25px;
  height: 170px;
}

.VRimgBox {
  background: white;
  border-radius: 15px;
  padding: 10px;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 34px;
}

.ADFont1 {
  font-weight: 700;
  font-size: 28px;
  margin-left: 23px;
}
.ADFont2 {
  font-weight: 700;
  font-size: 20px;
  margin-left: 23px;
}

.ADermargin {
  margin-left: 25px;
}

.successText {
  color: green;
  text-align: center;
}

.ERfromCalender {
  display: flex;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin: 4px 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .ExtendRentalCardSize .modal-dialog .modal-content {
    width: 374px;
    background: #ffffff;
    border-radius: 25px;
  }

  .ExtendRentalCardSize .modal-dialog {
    top: 15%;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .ExtendRentalCardSize .modal-dialog .modal-content {
    width: 345px;
  }
}

@media only screen and (max-width: 414) {
  .ExtendRentalCardSize .modal-dialog .modal-content {
    width: 397px !important;
  }
}
