#paginationNumbers_filter,
#paginationNumbers_info {
  display: none;
}
#paginationNumbers_wrapper {
  display: table;
  width: 100%;
  position: relative;
}
div#paginationNumbers_wrapper > div:first-child {
  display: table-footer-group;
}
div#paginationNumbers_wrapper > div:first-child .col-sm-6 {
  position: relative;
  top: -45px;
}
.MarginTop .col-sm-6 {
  margin-top: 20px;
}
div#paginationNumbers_wrapper > div:first-child .col-sm-6 label {
  display: flex;
  align-items: center;
  font-weight: normal;
  color: black;
}
div#paginationNumbers_wrapper
  > div:first-child
  #paginationNumbers_length
  select {
  width: auto;
  margin: 0 10px 0 10px;
  padding: 0 10px 0 10px;
  border: 1px solid #e2e2e2;
  border-radius: 5px !important;
}
div#paginationNumbers_wrapper > div:last-child {
  width: 80%;
  float: right;
}
.datatableContainer {
  padding: 20px;
}
#paginationNumbers {
  border: 1px solid #e2e2e2;
  border-radius: 15px;
  border-collapse: separate !important;
  border-spacing: 0;
}
#paginationNumbers thead tr {
  background: rgba(20, 36, 84, 0.05);
  border-radius: 15px;
  border-collapse: separate !important;
  border-spacing: 0;
}

#paginationNumbers thead th {
  font-weight: 400;
  font-size: 16px;
}

#paginationNumbers tbody td {
  font-weight: 400;
  font-size: 18px;
}
.paginate_button {
  padding: 0 10px 0 10px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0px 2px 0 2px;
}
.dataTables_paginate.paging_numbers {
  float: right;
}
.paginate_button.active {
  background-color: #e2e2e2;
}
.paginate_button a {
  color: black;
  text-decoration: unset;
}

.paddingZero {
  padding-left: 0px;
  padding-right: 0px;
}


.pageBody {
  overflow-y: unset;
  scrollbar-color: #000000 #ffffff;

}

@media only screen and (max-width: 720px) {
  .datatableContainer {
    padding: 0px 0 0px 0;
    padding: 11px;
  }
  /* .removeFromMobile {
    display: none;
  } */

  .loader2 {
    width: 50% !important;
  }

  .loader_positon {
    right: 42% !important;
  }
}

.hoverarrows {
  display: none;
  float: right;
  color: #c8ccd7;
  margin-top: 4px;
}

th:hover > .hoverarrows {
  display: initial;
}

.payNowStyle {
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #142454;
  background-color: #fff !important;
  border-color: #fff !important;
}
.payNowTotalStyle {
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #142454;
  background-color: #fff !important;
  border-color: #fff !important;
}
.payNowStyle:hover,
.payNowTotalStyle:hover {
  background-color: #fff !important;
  border-color: #fff !important;
}

.cursorPointerThead {
  cursor: pointer;
}

.paginate_button {
  z-index: 1;
}

.TableDisplayBlock {
  display: none;
}

.loader2 {
  width: 50%;
}

.loader_positon {
  position: absolute;
  right: 50%;
  /* top: 45%; */
  margin-top: 18%;
}

.border hr {
  background-color: #e2e2e2;
}

.border hr:not([size]) {
  height: 10px;
}

.wrongDialedCallTable tbody td {
  font-size: 14px !important;
}

.wrongDialedCallTable thead th {
  font-size: 15px !important;
}

.LogisticListTables #paginationNumbers_filter,
.LocationTable #paginationNumbers_filter {
  display: block !important;
}
.LogisticListTables
  div#paginationNumbers_wrapper
  .row:nth-child(1)
  .col-sm-6:nth-child(2),
.LocationTable
  div#paginationNumbers_wrapper
  .row:nth-child(1)
  .col-sm-6:nth-child(2) {
  position: absolute;
  padding-left: 5px;
}

#paginationNumbers_filter {
  width: inherit;
}
