.PageMargin {
  padding: 30px;
  scroll-behavior: smooth;
  margin-bottom: 5%;
}

.bundleSubmitBtn {
  background: #fbb434;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: black;
  padding: 12px 17px;
}

.PageMargin label {
  color: #656565;
  font-size: 16;
  font-weight: 400;
}

.input-group-text {
  border: 1px solid #e2e2e2;
  padding: 10px;
  border-right: none;
  background: rgb(252, 251, 250);
  border-radius: 0px;
}
