.gCard {
  border-radius: 25px;
  background-color: #f4f6f7;
  /* min-height: 262px; */
}
.gCard .btn.btn-primary {
  background-color: #1d4d5a !important;
  border-color: #1d4d5a !important;
  color: white !important;
}

.gTitle {
  font-weight: bold;
  font-size: 28px;
  color: #1d4d5a;
}
.gContainer {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  height: 204px;
}
.gBody {
  width: 74%;
  color: #142454;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.FreezeSim_mt {
  margin-top: 22px;
}
