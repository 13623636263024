@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

.sliderBody {
  /* max-width: 37.5rem; */
  margin: 0 auto;
  padding: 0 0.25rem 0 0;
  font-family: "Lato", sans-serif;
}

.sliderBody {
  box-sizing: border-box;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: 0px;
}
.scroller {
  overflow-y: scroll;
  scrollbar-color: #0a4c95 #c2d2e4;
}
.sliderBody::-webkit-scrollbar {
  width: 0;
}

.sliderBody::-webkit-scrollbar-track {
  background: transparent;
}

.sliderBody::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}

.sliderBody {
  -ms-overflow-style: none;
}

ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  padding-top: 330px;
  /* filter: drop-shadow(0 0 10px #0003); */
  perspective: 100px;
}

.carousel__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* display: flex; */
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
}
.carousel__navigation-button.active {
  background-color: #142454;
}
/* .carousel__slide:nth-child(even) {
} */

.carousel__slide:before {
  /* position: absolute;
    top: 50%;
    left: 50%; */
  transform: translate3d(-50%, -40%, 70px);
  color: #fff;
  font-size: 2em;
}

.carousel__snapper {
  /* position: absolute; */
  /* top: 30px; */
  left: 0;
  width: 100%;
  /* height: 100%; */
  scroll-snap-align: center;
}

@media (hover: hover) {
  .carousel__snapper {
    /* animation-name: tonext, snap; */
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .carousel__slide:last-child .carousel__snapper {
    animation-name: tostart, snap;
  }
}
.sliderH1 {
  font-weight: bold;
  font-size: 14px;
}
.sliderNum {
  font-weight: bold;
  font-size: 20px;
}
.sliderH2 {
  font-weight: bold;
  font-size: 15px;
}
.sliderH3 {
  font-weight: bold;
  font-size: 16px;
}

.sliderVal {
  font-weight: bold;
  font-size: 16px;
}
.invoices .dataContainer,
.rentals .dataContainer,
.dataContainer .col-lg-6 {
  margin-bottom: 3px;
}
.statusDue .sliderH2 {
  color: #4c1018;
}
.statusPaid .sliderH2 {
  color: #4b5a41;
}
.statusPaid .sliderH3 {
  color: #142454;
}
.carousel__slide:not(.active) {
  display: none;
}

.carousel__slide.active {
  display: block;
  animation: blinker2 1.1s linear;
  /* animation-name: blinker2;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 10s; */
}

@keyframes blinker2 {
  50% {
    opacity: 0;
  }
}

.viewDetails {
  color: #142454;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}
.statusActive {
  background: #f6f8f9;
  border-radius: 25px;
}
.imgBox {
  background: white;
  border-radius: 15px;
  padding: 10px;
  width: 40px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardContainer {
  min-height: auto;
  padding: 15px 19px 15px 19px;
}
.statusActive .sliderH2 {
  color: #4b758c;
}
.statusActive .sliderH3 {
  color: #142454;
}
@media (prefers-reduced-motion: reduce) {
  .carousel__snapper {
    animation-name: none;
  }
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
  animation-name: none;
}

.carousel__navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.carousel__navigation-list,
.carousel__navigation-item {
  display: inline-block;
}

.carousel__navigation-button {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #e2e2e2;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
}

.carousel::before,
.carousel::after,
.carousel__prev,
.carousel__next {
  position: absolute;
  top: 0;
  margin-top: 17%;
  width: 2.5rem;
  height: 2.5rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.carousel::before,
.carousel__prev {
  left: 0rem;
}

.carousel::after,
.carousel__next {
  right: 0rem;
}

.carousel::before,
.carousel::after {
  content: "";
  z-index: 1;
  background-color: #dfdfe56b;
  /* background-size: 1.5rem 1.5rem; */
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.statusDue {
  background: #f6f4f5;
  border-radius: 25px;
}
.statusPaid {
  background: #f6f7f5;
  border-radius: 25px;
}
.carousel::before {
  background-image: url("/public/images/leftArrow.png");
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .invoiceContainer .gCard{
    height: 38vh;
   }
   .rentalContainer .rec-carousel-item .cardContainer {
     height: 40vh;
   }
}

.carousel.oneRecOnly::before,
.carousel.oneRecOnly::after {
  display: none;
}

.carousel::after {
  background-image: url("/public/images/rightArrow.png");
}

.carousel__slide .rentals {
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 8px;
  height: 100%;
}

.rental_slider{
  width: 100%;
}


@media only screen and (max-width: 775px) {
  .rental_slider{
    width: unset;
  }

}

@media only screen and (max-width: 720px) {


  .carousel::before,
  .carousel::after,
  .carousel__prev,
  .carousel__next {
    position: absolute;
    top: 0;
    margin-top: 65%;
    width: 2.5rem;
    height: 2.5rem;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    outline: 0;
  }
  .pno {
    margin-bottom: 16px;
  }

  .Sactive {
    margin-bottom: 16px;
  }

  .Efee {
    margin-top: 16px;
  }

  .button {
    margin-top: 40px;
  }

  .carousel {
    position: relative;
    padding-top: 460px;
    /* filter: drop-shadow(0 0 10px #0003); */
    perspective: 100px;
  }
  .sliderH3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button2 {
  z-index: 2;
}

/* .sliderH3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.NextPrevBtn {
  z-index: 1;
  width: 50px;
  height: 50px;
  align-self: center;
  border-radius: 50%;
  background-color: #dfe3e9;
  border-color: transparent;
}

.sliderBody .rec-carousel-wrapper .rec-pagination button.rec-dot_active {
  background-color: #142454 !important;
  box-shadow: none !important;
}

.sliderBody .rec-carousel-wrapper .rec-pagination button {
  background-color: #e2e2e2 !important;
  box-shadow: none !important;
}

.rec-slider-container {
  margin: 0px -30px !important;
}

.cursor {
  cursor: not-allowed !important;
  opacity: 0.65;
}

.rec-pagination {
  margin-top: 0px !important;
  place-content: center;
}
