.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
