.paymentMargein {
  margin-bottom: 5%;
  scroll-behavior: smooth;
  margin-left: 2%;
}

.topContainer {
  display: flex;
  padding: 10px;
  height: 72px;
  left: 230px;
  top: 62px;
  background: rgba(20, 36, 84, 0.05);
  border-radius: 25px 0px 0px 0px;
}

.font1 {
  align-self: center;
  margin-left: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.MasterContainer {
  width: 325px;
  height: 206px;
  background: linear-gradient(67.65deg, #ffa200 2.01%, #ff5f71 97.27%);
  box-shadow: 0px 13px 76px rgba(0, 0, 0, 0.07),
    0px 2.6px 12.35px rgba(0, 0, 0, 0.035);
  border-radius: 15px;
}

.VisaContainer {
  width: 325px;
  height: 206px;
  background: linear-gradient(67.65deg, #00ffa3 2.01%, #2100ea 97.27%);
  box-shadow: 0px 13px 76px rgba(0, 0, 0, 0.07),
    0px 2.6px 12.35px rgba(0, 0, 0, 0.035);
  border-radius: 15px;
  border-radius: 15px;
}

.DiscoverContainer {
  width: 325px;
  height: 206px;
  background: linear-gradient(67.65deg, #82bbc2 19.01%, #244e4f 97.27%);
  box-shadow: 0px 13px 76px rgb(0 0 0 / 7%), 0px 2.6px 12.35px rgb(0 0 0 / 4%);
  border-radius: 15px;
  border-radius: 15px;
}
.AddContainer {
  width: 325px;
  height: 206px;
  background: rgba(20, 36, 84, 0.15) !important;
  border: 1px solid #142454 !important;
  box-sizing: border-box;
  box-shadow: 0px 13px 76px rgba(0, 0, 0, 0.07),
    0px 2.6px 12.35px rgba(0, 0, 0, 0.035) !important;
  border-radius: 15px;
}
.MasterImg {
  margin-top: 25px;
  margin-left: 10px;
}

.VisaImg {
  margin-top: 35px;
  margin-left: 10px;
}

.DicoverImg {
  margin-top: 17px;
  margin-left: 10px;
}
.font2Card {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: left;
}

.McardMarginTop {
  margin-top: 15px;
}

.VcardMarginTop {
  margin-top: 25px;
}

.DcardMarginTop {
  margin-top: -2px;
}

.font3 {
  color: #142454;
  font-size: 30px;
}

.font4 {
  color: #142454;
  font-size: 20px;
}

.AddCardStyle {
  text-align: center;
  margin-top: 75px;
}

.deleteCircle {
  background-color: #fbb434 !important;
  width: 42px;
  height: 42px;
  border-radius: 50% !important;
  text-align: center;
}

.deleteIcon {
  margin-top: -7px;
}

.deleteButton {
  float: right;
  margin-right: -32px;
  margin-top: -17px;
}

.deleteCardSize .modal-dialog .modal-content {
  width: 481px;
  height: 289px;
  background: #ffffff;
  border-radius: 25px;
  padding: 0px 18px;
}

.deleteCardSize .modal-dialog {
  position: relative;
  /* margin: 0 auto; */
  width: 481px;
  top: 15%;
}
.DCfont1 {
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  color: #142454;
  margin-left: 27px;
  white-space: nowrap;
}

.DCfont2 {
  font-weight: 700;
  font-size: 18px;
  justify-content: center;
}

.DCfont3 {
  font-weight: 700;
  font-size: 18px;
  color: #142454;
  justify-content: center;
}

.dismissDCbtn {
  margin-left: 32px;
  border-radius: 10px;
  border-color: #fbb434;
  border-style: none;
  background-color: #fbb434;
  width: 203px;
  height: 39px;
  float: right;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.NWidht {
  width: 175px;
}

.addNewCardSize .modal-dialog .modal-content {
  width: 408px;
  background: #ffffff;
  border-radius: 25px;
}

.dismissANCbtn,
.SaveANCBtn {
  width: 177px !important;
  margin-top: 5px !important;
}

.SaveANCBtn {
  float: none;
}

.message {
  text-align: center;
  color: green;
  margin-left: 26px;
}
.addNewCardSize .modal-dialog {
  max-width: 408px;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .paymentMargein {
    margin-bottom: 20%;
  }
  .deleteCardSize .modal-dialog .modal-content {
    width: 375px !important;
  }

  .addNewCardSize .modal-dialog .modal-content {
    width: 374px !important;
  }

  .addNewCardSize .modal-dialog {
    top: 15%;
  }

  .deleteCardSize .modal-dialog {
    top: 30%;
  }
  .NWidht {
    width: 157px;
  }

  .Mwidth {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .placeContent {
    place-content: center;
  }
  .textCenter {
    text-align: center;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.BTNCenter {
  text-align: center;
}

.Dismis {
  float: none !important;
}

.payImgBox {
  background: white;
  border-radius: 15px;
  padding: 10px;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgmarLeft {
  margin-left: 6px;
}

.font2M {
  margin: 2px 0px;
}

.fontspacing {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.FontSizeUpdate {
  font-size: 17px;
}

@media only screen and (max-width: 360px) {
  .addNewCardSize .modal-dialog .modal-content {
    width: 345px !important;
  }
}

@media only screen and (max-width: 410) {
  .addNewCardSize .modal-dialog .modal-content {
    width: 395px !important;
  }
}

.c142454 {
  color: #142454;
}
.PB_Font1 {
  font-weight: 400;
  font-size: 18px;
}
.PB_Font2 {
  font-weight: 700;
  font-size: 20px;
}

.PB_flexEnd {
  display: flex;
  justify-content: flex-end;
}
