.pageMargin {
  padding: 20px;
  /* margin-top: -20px; */
  scroll-behavior: smooth;
  margin-bottom: 5%;
}

.recentOrderContainer {
  background-color: #fdf7f3;
  border-radius: 25px;
}

.boxCircle {
  width: 52px;
  height: 52px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d95b08 {
  color: #d95b08;
}
.c142454 {
  color: #142454;
}
.ro-font1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}
.ro-font2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.ro-font3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.ro-font4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.ro-font5 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

.ro-button {
  background: #fbb434;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}
.ro-button-close {
  background: #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
  border-color: #142454 !important;
}
.ro-button2 {
  background: #1d4d5a;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: white;
  padding: 12px 17px;
}
.ro-button2:hover {
  color: white;
}

.form-check-input:checked {
  background-color: #c6cad6 !important;
  border-color: #c6cad6 !important;
}

.form-check-input {
  background-color: #c6cad6 !important;
  border-color: #c6cad6 !important;
}
.form-check-input[type="radio"] {
  background-color: #fff !important;
  border-color: #fff !important;
  border: 1px solid #142454 !important;
}
.form-check-input[type="radio"]:checked {
  background-color: #142454 !important;
  border-color: #142454 !important;
}
.form-check-input:checked[type="radio"] {
  background-image: none;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url(../../../public/images/LocationPortal/tick.svg) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 25px;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 1 !important;
}

.select {
  padding: 10px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 4px 0px;
}

.optionalContainer {
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}
.optionalContainer > div:nth-child(1) {
  background: rgba(20, 36, 84, 0.05);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px solid #e2e2e2;
}

@media (min-width: 768px) {
  .cus-md-2 {
    flex: 0 0 auto;
    width: 19.99%;
  }
}

.LogisticListTable {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.LogisticListTable td,
.LogisticListTable th {
  width: 80px;
}
.LogisticListTable .first-col {
  left: 0;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}
.LogisticListTable .second-col {
  left: 80px;
  width: 135px;
  min-width: 135px;
  max-width: 135px;
}
.LogisticListTable .sticky-col {
  position: sticky;
  position: -webkit-sticky;
}

th div:hover > .hoverarrows {
  display: initial;
}

.hoverarrows {
  margin-left: 5px;
}

.LogisticListTable.active thead tr th,
.LogisticListTable.active tbody tr td {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.LogisticListTable thead tr th,
.LogisticListTable tbody tr td {
  cursor: pointer;
}

.paginationNumbers_wrapper {
  position: relative;
}
.paginationNumbers_wrapper:first-child {
  color: red;
}

.col-cus-2 {
  flex: 0 0 auto;
  width: 12%;
}

@media only screen and (max-width: 720px) {
  .col-cus-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .LogisticListTables
    div#paginationNumbers_wrapper
    .row:nth-child(1)
    .col-sm-6:nth-child(2) {
    position: absolute;
    top: 0px;
    padding-left: 5px;
  }
  .LocationTable
    div#paginationNumbers_wrapper
    .row:nth-child(1)
    .col-sm-6:nth-child(2) {
    position: absolute;
    top: -315px;
    padding-left: 5px;
  }

  .LogisticListTables div#paginationNumbers_wrapper .row:nth-child(2),
  .LocationTable div#paginationNumbers_wrapper .row:nth-child(2) {
    padding-top: 50px;
  }

  .LogisticListTables #paginationNumbers_filter label {
    font-weight: bold !important;
    font-size: 16px;
    line-height: 19px;
    color: #142454 !important;
  }
  .LogisticListTables #paginationNumbers_filter label input {
    margin-left: 10px;
  }
  .LocationTable #paginationNumbers_filter label input {
    width: auto;
    margin-left: 17px;
  }
}
