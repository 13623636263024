.MyAfont1 {
  font-weight: 700;
  font-size: 19px;
  color: #142454;
  cursor: pointer;
  /* border-bottom-style: solid; */
  /* border-bottom-color: #142454; */
  padding: 8px 0px 21px 0px;
}
.borderColorPD {
  border-bottom-style: solid;
}
.SSmargin {
  margin-right: 100px;
}
.AcctopContainer {
  padding: 10px;
  height: 72px;
  left: 230px;
  top: 62px;
  background: rgba(20, 36, 84, 0.05);
  border-radius: 25px 0px 0px 0px;
}

.rowMargin {
  margin-left: 0px;
}

.accImg {
  border-radius: 10px;
}

.accountName {
  color: #142454;
  font-weight: 400;
}

.successMsg {
  color: green;
}
[src="/images/dummyUser.jpg"] {
  border: 4px solid #f8b538;
}

.editContainer {
  background-color: #fbb434;
  width: 42px;
  height: 42px;
  border-radius: 28px;
  position: absolute;
  /* right: 50%; */
  margin-left: 102px;
  top: -15%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.uploadBtn {
  width: -webkit-fill-available;
  width: -moz-available;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .SSmargin {
    margin-right: 10px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }


}

@media only screen and (max-width: 580px) {
  .m-0.p-0.phoneresponsv {
    display: block !important;
}
.moblieres{
  text-align: center;
}
}
@media only screen and (max-width: 380px) {
  .MyAfont1 {
    font-size: 18px;
  }
  .mobile {
    display: none;
  }
}
