.usageSize .modal-dialog {
  max-width: 706px;
  margin: 1.75rem auto;
}

.usageSize .modal-dialog .modal-content {
  width: 706px;
  background: #ffffff;
  border-radius: 25px;
  padding: 0px 18px;
  height: 580px;
}
/* 
.changePlanDSize .modal-dialog .modal-content {
  width: 372px;
  background: #ffffff;
  border-radius: 25px;
  padding: 0px 18px;
} */

.font1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #142454;
}

.font2 {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 25px 15px;
}

.font3 {
  font-size: 16px;
  line-height: 19px;
  color: #142454;
  font-weight: bold;
  margin: 4px 0px;
}

.font4 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.font5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 48px;
}

.selectBox {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  margin: 4px 0px;
  width: 380px !important;
}

.box1,
.box2 {
  width: 306px;
  border-radius: 15px;
}

.box1 {
  background-color: #e4e6e3;
  margin-left: 12px;
}

.box2 {
  background-color: #e4eaee;

  margin-left: 21px;
}

.statusBox {
  width: 378px;
}

.statusPlan {
  margin-top: 48px;
}

.dismissbtn {
  border-radius: 10px;
  margin-top: 58px;
  border-color: #142454 !important;
  background-color: #ffffff;
  width: 203px;
  height: 39px;
  float: right;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.dismissbtnDetails {
  border-radius: 10px;
  border-color: #ffffff;
  background: #fbb434;
  width: 203px;
  height: 39px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
  border-style: none;
}

.changebtn {
  border-radius: 10px;
  margin-top: 58px;
  border-color: #ffffff;
  background: #fbb434;
  width: 203px;
  height: 39px;
  border-style: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.changePlan .btn-primary {
  background-color: #f3f4f6 !important;
  border-color: #142457 !important;
  width: -webkit-fill-available;
  width: -moz-available;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .usageSize .modal-dialog .modal-content {
    width: 370px !important;
    background: #ffffff;
    border-radius: 25px;
    margin-left: 10px;
    height: auto;
  }
  .changePlanDSize .modal-dialog .modal-content {
    width: 372px !important;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px 18px;
  }

  .changebtnMmargin {
    float: right;
  }

  /* .changePlan {
    display: flex;
    align-items: center;
    height: 42px;
    border-radius: 10px;
    border-color: #142454;
  } */
  .statusBox {
    width: 300px;
  }

  .statusPlan {
    margin-top: 22px;
  }

  .box1 {
    margin-bottom: 15px;
  }

  .box2 {
    margin-left: 10px;
  }

  .changebtn .btn-primary {
    margin-top: 0;
  }
  .font5 {
    margin-top: 20px;
  }

  .dismissbtn {
    float: none;
    margin-top: 40px;
  }

  .mobileCenter {
    text-align: center;
  }

  .changebtn {
    margin-top: 10px;
  }

  .DataTableUsageMargin {
    margin-left: 5px !important;
    margin-right: 15px;
  }
  .changeplanMbtn .btn-primary {
    background-color: #ffffff !important;
    border-color: #142454 !important;
  }
  /* .mMargin .changeplanMbtn {
  } */
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.DataTableUsageMargin {
  margin-left: 15px;
}
