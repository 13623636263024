.cancelOrderSize .modal-dialog .modal-content {
  width: 481px;
  height: 289px;
  background: #ffffff;
  border-radius: 25px;
  padding: 0px 18px;
}

.orderStatusContainer {
  width: -moz-available;
  width: -webkit-fill-available;
  height: auto;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0px;
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 50px;
}

.productTitleContainer {
  background: rgba(20, 36, 84, 0.05);
  border-radius: 15px 15px 0px 0px;
}

.OSfont1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}

.OSfont2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fbb434;
  margin: 4px 0px;
}

.OSfont3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #4b758c;
  margin: 4px 0px;
}

.OSfont4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #142454;
  margin: 4px 0px;
}

.textright {
  text-align: right;
}

.OSYellowbtn {
  border-radius: 10px;
  border-color: #ffffff;
  background: #fbb434;
  height: 39px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
  border-style: none;
}

.OSRedbtn,
.OSYellowbtn {
  width: 186px;
}

.shippedCenter {
  text-align: center;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .cancelOrderSize .modal-dialog .modal-content {
    width: 375px;
    height: 349px;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px 18px;
  }

  .CanOrdfont1M {
    font-weight: bold !important;
    font-size: 21px !important;
    line-height: 34px;
    color: #142454;
  }
  .orderStatusContainer {
    width: -webkit-fill-available;
    width: -moz-available;
    height: auto;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0px;
    margin-left: 32px;
    margin-top: 20px;
    margin-right: 35px;
  }

  .dismissOSbtn,
  .confirmbtn {
    width: 156px !important;
  }

  .productTitleContainer {
    height: auto;
  }

  .MobileMargin {
    margin-top: 20px;
  }
  .OSYellowbtn {
    width: 142px;
    margin-bottom: 12px;
  }

  .OSRedbtn {
    width: 99%;
  }

  .shippedCenter,
  .textright {
    text-align: left;
  }

  .orderPlacedDesign {
    margin-top: -18px;
  }

  .shippedCenter {
    margin-top: 37px;
  }

  .textright {
    margin-top: 90px;
  }

  .orderPlacedDesign,
  .shippedCenter,
  .textright {
    margin-left: 89px;
  }
  .OSprogressM-bar::before {
    content: "";
    background-color: #c4c4c4;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: -1;
    border: 1px solid #c4c4c4;
  }
  .OSprogressM {
    content: "";
    background-color: #4b5a41;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    /* width: 62%; */
    z-index: -1;
    transition: 0.4s ease;
  }
  .OSprogressM-bar {
    display: flex;
    max-width: 69%;
    justify-content: space-between;
    color: #999;
    position: inherit;
    padding: 0%;
    margin-left: 50px;
    /* margin-top: 24px; */
    /* margin-right: 14px; */
    transform: rotate(90deg);
    -webkit-transform-origin-x: left;
  }
  .OSprogressM-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #c4c4c4;
    border-radius: 50%;
    padding: 13px;
    width: 10px;
    height: 10px;
    background-color: #c4c4c4;
    transition: 0.4s ease-out;
  }

  .OSprogressM-bar .active {
    border-color: #4b5a41;
    background-color: #4b5a41;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

/* progess bar */

.OSprogress-bar::before {
  content: "";
  background-color: #c4c4c4;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
  border: 1px solid #c4c4c4;
}
.OSprogress {
  content: "";
  background-color: #4b5a41;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  /* width: 62%; */
  z-index: -1;
  transition: 0.4s ease;
}
.OSprogress-bar {
  display: flex;
  /* max-width: 96%; */
  justify-content: space-between;
  color: #999;
  position: relative;
  /* padding: 0%; */
  margin-left: 15px;
  margin-top: 24px;
  margin-right: 14px;
}
.OSprogress-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #c4c4c4;
  border-radius: 50%;
  padding: 13px;
  width: 10px;
  height: 10px;
  background-color: #c4c4c4;
  transition: 0.4s ease-out;
}

.OSprogress-bar .active {
  border-color: #4b5a41;
  background-color: #4b5a41;
}

.OSRedbtn .btn-primary {
  background: #fb3434 !important;
  height: 39px;
  width: -webkit-fill-available;
  width: -moz-available;
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.OSRedbtn {
  padding: 0%;
}

/* Cancel Order Popup */

.CanOrdfont1 {
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  color: #142454;
  white-space: nowrap;
}

.CanOrdfont2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.CanOrdfont3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #142454;
}

.dismissOSbtn {
  border-radius: 10px;
  margin-top: 26px;
  border-color: #142454 !important;
  background-color: #ffffff;
  width: 203px;
  height: 39px;
  float: right;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.dismissCOSbtn {
  border-radius: 10px;
  margin-top: 26px;
  border-color: #fbb434;
  border-style: none;
  background-color: #fbb434;
  width: 203px;
  height: 39px;
  float: right;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.confirmbtn {
  border-radius: 10px;
  margin-top: 26px;
  border-color: #fbb434 !important;
  background-color: #fbb434;
  border-style: none;
  width: 203px;
  height: 39px;
  float: right;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.CancelledOrdfont1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #142454;
  margin-left: 23px;
}
.CancelledOrdfont2 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.centerdismissBtn {
  justify-content: center;
}
.c142454 {
  color: #142454;
}
.c4B758C {
  color: #4b758c;
}
.ODFont1 {
  font-weight: 700;
  font-size: 20px;
}
.ODFont2 {
  font-weight: 400;
  font-size: 18px;
}
.ODFont3 {
  font-weight: 400;
  font-size: 18px;
}
.OD_Container {
  background: #f3f4f7;
  border-radius: 25px;
}

.ODFont4 {
  font-weight: 700;
  font-size: 24px;
}
.ODFont5 {
  font-weight: 700;
  font-size: 23px;
}
.vr {
  border: 1px solid #e2e2e2;
  transform: rotate(-90deg);
}

@media only screen and (min-width: 720px) {
  .vertical {
    border-left: 1px solid #e2e2e2;
    height: 380px;
    position: absolute;
    left: 50%;
  }
  .OS_Conatiner {
    padding: 2rem !important;
  }
}
