.invoicePageBody {
  padding-top: 0;
  padding-left: 12px;
  min-height: 92vh;
}

.containerMargin {
  margin-left: 12px;
}

.filterInvoice {
  color: #142454;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* position: absolute;
  width: 174px;
  height: 34px;
  left: 16px;
  top: 69px; */
  margin-bottom: 15px;
}

.closebtn {
  text-decoration-color: #142454;
  text-align: center;
  font-weight: 700;
  font-size: 23px;
}
.pullRight {
  float: right;
}
.filterContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 97px;
  margin-left: 0;
  background: rgba(20, 36, 84, 0.05);
  border-radius: 25px 0px 0px 0px;
}

.filterContainerUsage {
  display: flex;
  padding: 10px;
  height: 97px;
  left: 230px;
  top: 62px;
  background: rgba(20, 36, 84, 0.05);
  border-radius: 25px 0px 0px 0px;
}

/* .invoiceContainer {
  position: absolute;
} */

.fromDate,
.toDate,
.statusData,
.phoneData {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
  margin: 4px 0px;
}

.fromCalender,
.toCalender {
  /* display: flex; */
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin: 4px 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.statusbtn,
.phonebtn {
  display: flex;
  padding: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding-right: 62px;
}

.applybtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 42px;
  background: #fbb434;
  border-radius: 10px;
  margin-top: 28px;
}

.applybtnUsage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 42px;
  background: #fbb434;
  border-radius: 10px;
  margin-top: 28px;
}

.applyFont {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #142454;
}

.invoiceTable {
  padding: 6px;
  background: rgba(20, 36, 84, 0.05);
  border-radius: 15px 15px 0px 0px;
  margin-left: 20px;
  width: 1078px;
}

.tableMarginTop {
  margin-top: 20px;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .invoicePageBody {
    border-radius: 0px;
  }

  .mRight {
    margin-right: 10px;
  }
  .filterContainer {
    width: 387px;
    background: #ffffff;
    margin-bottom: 300%;
    margin-left: 4px;
  }

  .filterContainerUsage {
    width: 387px;
    background: #ffffff;
    margin-bottom: 300%;
  }

  .containerMargin {
    margin-left: 0px;
  }

  .fromCalender,
  .toCalender {
    width: 342px;
  }

  .applybtn {
    width: 342px;
    height: 42px;
    background: #fbb434;
    border-radius: 10px;
    margin: 0px;
    margin-top: 28px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .filterContainerMobile {
    display: none;
  }
  .contanierWidthUsage {
    width: -webkit-fill-available !important;
  }

  .changePlanM {
    border-radius: 10px !important;
    border-color: #142454 !important;
    float: right;
    display: flex;
    align-items: center;
    height: 42px;
    width: unset;
  }
  .mPadding {
    padding: 15px;
    padding-right: 25px;
  }
}

.contanierWidthUsage {
  width: 210px;
}

.changePlan {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 42px;
  border-radius: 10px;
  margin-top: 28px;
}

.mr {
  margin-left: 20px;
  margin-right: 15px;
}
