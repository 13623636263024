.closebtn {
  color: #142454;
}

.closebt {
  font-size: small;
}

.Font {
  color: #142454;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.infoSmallText{
  color: green;
  font-size: 14px;
}
.bigFont {
  color: #142454;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 15px;
}

.textFont {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 15px;
}
.alldetailsFont {
  line-height: 34px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 28px;
  color: #142454;
  margin-left: 30px;
}
.box {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  width: 376px;
  padding: 10px;
  margin: 4px 0px;
}

.selectbtn {
  width: 376px;
  padding: 10px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 4px 0px;
}

.Font {
  margin-top: 15px;
}

.applybtn1 {
  background: #fbb434;
  border-radius: 10px;
  padding: 10px 42px;
  width: 376px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.middleSize .modal-dialog .modal-content {
  width: 442px;
  background: #ffffff;
  border-radius: 25px;
  padding: 20px 32px;
}

.middleSize .modal-dialog {
  width: 442px;
}

.middleSize .modal-dialog .modal-content .modal-body {
  padding: 0px;
  position: initial;
  margin: 0 auto;
  top: 0%;
}

.selectbtn .btn {
  margin-bottom: 10px;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .middleSize .modal-dialog .modal-content {
    width: 374px;
    background: #ffffff;
    border-radius: 25px;
    padding: 10px 32px;
    margin-top: 46px;
  }

  .selectbtn,
  .box,
  .applybtn1 {
    width: 310px;
    margin-bottom: 15px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.popup {
  display: none;
}

.closebtn2 {
  font-size: x-large;
  color: #142454;
  float: right;
  font-weight: 600;
  margin-top: -20px;
  margin-right: -17px;
}

.popupsize .modal-dialog .modal-content {
  background: #ffffff;
  border-radius: 25px !important;
  padding: 0px 18px;
}
.popupsize .modal-dialog {
  position: relative;
  top: 35%;
}
.applybtn2 {
  background: #fbb434;
  border-radius: 10px;
  padding: 10px 11px;
  width: 63px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 316px;
}

.paddingLeft {
  padding-left: 0%;
}
